<template>
  <CRow>
    <CCol col="12">
      <CModal :title="title" size="lg" :show.sync="schedule" :no-close-on-backdrop="true" color="primary">
        <CForm novalidate @submit.prevent="onSubmit" enctype='multipart/form-data'>
          <CCardBody>
            <CRow>

              <CCol md="6" v-show="calendar.meetingLink">
                <div class="form-group"  >
                  <label class="form__label"><b>{{ $lang.calendar.form.registeredAs }}</b></label><br>
                  {{ getCalendar.registeredAs }}
                </div>
              </CCol>
              <CCol md="6" >
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.name }}</b></label><br>
                  {{ getCalendar.name }}
                </div>
              </CCol>

              <CCol md="6" >
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.date }}</b></label><br>
                  {{ getCalendar.displayScheduleDate }}
                </div>
              </CCol>
              <CCol md="6" >
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.time }}</b></label><br>
                  {{ getCalendar.displayStartTime }} <b>To</b>  {{ getCalendar.displayEndTime }}
                </div>
              </CCol>

              <CCol md="6" :hidden="user_hidden" v-if="getCalendar.isCompleted==0">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.meetingLink }}</b></label><br>
                  <a :href="calendar.meetingLink" target="_blank">Join Meeting</a><br>

                </div>
              </CCol>

              <CCol md="6" :hidden="user_hidden">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.staffPassword }}</b></label><br>
                  {{ calendar.staffPassword }}
                </div>
              </CCol>
              <CCol md="6" :hidden="user_hidden">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.meetingStatus }}</b></label><br>
                  {{ calendar.meetingStatus }}
                </div>
              </CCol>

              <CCol md="6" v-if="getCalendar.isCompleted==1">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.verifyAt }}</b></label><br>
                  {{ calendar.verifyAt }}
                </div>
              </CCol>
              <CCol md="6" v-show="calendar.description" >
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.description }}</b></label><br>
                  {{ calendar.description }}
                </div>
              </CCol>


              <CCol md="6" :hidden="footer_hidden">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.staff }}</b></label><br>
                  {{ calendar.user }}
                </div>
              </CCol>
              <CCol md="6" :hidden="user_hidden">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.staff }}</b></label><br>
                  <v-select id="user" :options="userOptions" v-model="calendar.user"></v-select>
                  <small class="error" v-if="$v.calendar.$error && !$v.calendar.user.required">{{
                      $lang.calendar.validation.required.staff
                    }}</small>
                </div>
              </CCol>

              <CCol md="6" :hidden="user_hidden">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.userId }}</b></label><br>
                  {{ calendar.mobileuser }}
                </div>
              </CCol>

              <CCol>
                <a :href="calendar.profileLink" style="float: right" class="btn btn-primary right" target="_blank">
                  <CIcon name="cil-check-circle"/>
                  View Profile
                </a>
              </CCol>

            </CRow>
          </CCardBody>
          <CCardFooter>
          <span :hidden="user_hidden">
            <CButton color="primary" v-on:click="editSchedule()" :hidden="hidden" style="float: left;">
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.submit }}
            </CButton>
          </span>
          <span :hidden="footer_hidden">
            <CButton color="danger" v-on:click="deleteSchedule()" :hidden="hidden" style="float: right;">
              <i class="fas fa-trash"></i>
              {{ $lang.buttons.general.crud.delete }}
            </CButton>
          </span>
          <span :hidden="user_hidden" style="padding-left: 5px;">
            <CButton color="primary" v-on:click="resendSchedule()" :hidden="hidden">
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.resend }}
            </CButton>
          </span>
          </CCardFooter>
        </CForm>
        <template #footer style="display: none;">
          <CButton @click="darkModal = false" color="danger" style="display: none;">Discard</CButton>
          <CButton @click="darkModal = false" color="success" style="display: none;">Accept</CButton>
        </template>
      </CModal>
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.calendar.title }}</strong>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol md="6">
              <div class="form-group">
                <label class="form__label">{{ $lang.calendar.form.staff }}
                  <required_span/>
                </label>
                <v-select id="staff" :options="staffOptions" v-model="staffs" @option:selected="changeStaff"></v-select>
              </div>
            </CCol>
            <CCol class="mfs-auto">
              <CButton shape="pill" color="primary" @click="createTableRow" style="float: right;">
                Create Calendar
              </CButton>
            </CCol>
          </CRow>
          <FullCalendar :options="calendarOptions"/>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import moment from 'moment';
import {Mixin} from "/src/mixins/index.js";
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import required_span from "../../components/layouts/general/required-span";
import {required} from "vuelidate/lib/validators";
import {calendar} from "/src/store/url.js";
import {Settings} from "../../store/settings";
import 'vue2-timepicker/dist/VueTimepicker.css'
import options from "/src/validations/validations.js";

Vue.use(VueForm, options);

export default {
  name: 'CalendarView',
  mixins: [Mixin],
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    required_span,
  },
  data() {
    return {
      self: this,
      schedule: false,
      module: calendar,
      hidden: true,
      div_hidden: false,
      footer_hidden: false,
      user_hidden: true,
      title: '',
      edit_id: '',
      staffs: '',
      getCalendar:[],
      calendar: {
        date: '',
        start_time: '',
        end_time: '',
        user: '',
        meetingLink: '',
        description: '',
        mobileuser: '',
        verifyAt: '',
        meetingStatus: '',
        profileLink: '',
        staffPassword: ''
      },
      userOptions: [],
      staffOptions: [],
      Settings: Settings,
      calendarOptions: {
        selectable: true,
        editable: false,
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
        initialView: 'timeGridDay',
        nowIndicator: true,
        allDaySlot: false,
        slotDuration: '00:10:00',
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        selectAllow: function (select) {
          return moment().diff(select.start) <= 0
        },
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'timeGridDay,timeGridWeek,dayGridMonth'
        },
        events: [],
        eventRender: this.handleEventRender,
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: false
        },
        dayMaxEvents: true,
      },
    }
  },
  validations: {
    calendar: {
      user: {
        required
      },
    }
  },
  mounted() {
    let self = this;
    const staffId = this.$route.params.id;
    let link = '/calendar/get-calender';
    if (staffId) {
      link = '/calendar/get-calender/' + staffId;
      axios.get('/calendar/verifier-staff/list').then((response) => {
        response.data.data.map(function (value, key) {
          if (value.id === staffId) {
            self.staffs = {value: value.id, label: value.name};
          }
        });
      });
    }
    axios.post(link)
        .then((response) => {
          self.calendarOptions.events = response.data.data;
        });
    axios.get('/calendar/verifier-staff/list').then((response) => {
      response.data.data.map(function (value, key) {
        self.staffOptions.push({value: value.id, label: value.name});
      });
    });

    store.commit('showLoader', false);
  },
  methods: {
    handleEventClick(clickInfo) {
      // if(this.checkPermission("edit-availability")) {
      this.edit_id = clickInfo.event.id;
      this.hidden = false;
      this.div_hidden = true,
          this.footer_hidden = false;
          this.user_hidden = true;
          this.schedule = true;
      this.title = 'Edit Timetable';
      let self = this;
      axios.post('/calendar/get-timetable-detail/' + this.edit_id)
          .then(function (response) {
            if (response.status === 200) {
              let calendar = response.data.data;
              self.getCalendar = calendar;
              self.calendar.date = calendar.startDate;
              self.calendar.start_time = calendar.startTime;
              self.calendar.end_time = calendar.endTime;
              self.userOptions = [];
              if (calendar.userIds > 0) {
                self.title = 'View Timetable';
                self.footer_hidden = true;
                self.user_hidden = false;
                self.calendar.meetingLink = calendar.meetingLink;
                self.calendar.profileLink = '#/profile/view/' + calendar.userIds;
                if(calendar.isCompany == 1)
                {
                  self.calendar.profileLink = '#/college/view/' + calendar.collegeCompanyId + '/'+calendar.userIds;
                }
                self.calendar.description = calendar.description;
                self.calendar.mobileuser = calendar.user;
                self.calendar.verifyAt = calendar.verifyAt;
                self.calendar.meetingStatus = calendar.meetingStatus;
                self.calendar.staffPassword = calendar.staffPassword;
                calendar.users.map(function (value, key) {
                  self.userOptions.push({id: value.id, label: value.staffName});
                });
                self.calendar.user = {
                  id: calendar.userId, label: calendar.userName
                };
              } else {
                self.calendar.user = calendar.userName;
              }
            }
          });
      // }
    },
    getHumanStartDate: function (date) {
      return moment(date, 'YYYY-MM-DD').format(Settings.date.format);
    },
    changeStaff: function (val) {
      this.$router.push({
        path: '/calendar/show/' + val.value,
      })
    },
    handleEventRender(info) {
      const tooltip = new Tooltip(info.el, {
        title: info.event.extendedProps.description,
        placement: 'top',
        trigger: 'hover',
        container: 'body'
      });
    },
    deleteSchedule() {
      let self = this;
      axios.delete('/calendar/delete_slot/' + self.edit_id)
          .then(function (response) {
            window.location.reload();
          });
    },
    resendSchedule() {
      let self = this;
      axios.get('/calendar/resend_mail/' + self.edit_id+'/'+'Company')
        .then(function (response) {
          window.location.reload();
        });
    },
    createTableRow() {
      this.$router.push({
        path: '/calendar',
      })
    },
    editSchedule() {
      this.$v.calendar.$touch();
      if (this.$v.calendar.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        setTimeout(() => {
          let self = this;
          const formData = new FormData();
          formData.append('id', self.edit_id);
          formData.append('staffId', self.calendar.user.id);
          const link = '/calendar/update_slot';
          axios.post(link, formData)
              .then(function (response) {
                if (response.data.status === 200) {
                  window.location.reload();
                }
              }).catch(function (error) {
          });
        }, 500)
      }
    }
  }
}
</script>
<style>
.vue__time-picker input.display-time {
  float: left;
  width: 100%;
}

#time_picker[readonly] {
  background-color: transparent;
  opacity: 1;
}
</style>
